import "./App.css"
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { Auth, User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import SetEditor from './components/SetEditor';
import MySets from './components/MySets';
import Set from './components/Set';
import Learn from "./components/Learn";

const firebaseConfig = {
	apiKey: "AIzaSyCaEyWDp9xHRQAOkVo4f9Nc3YbVmrAplLw",
	authDomain: "quizco-3dac0.firebaseapp.com",
	projectId: "quizco-3dac0",
	storageBucket: "quizco-3dac0.appspot.com",
	messagingSenderId: "799418917684",
	appId: "1:799418917684:web:a1a9561fe879fb30f77b61",
	measurementId: "G-HQXTDGDQP8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

	const [auth, setAuth] = useState<Auth | null>(null);
    const [user, setUser] = useState<User | null>(null);

    async function addDocToUsers(uid: string) {
        const fs = getFirestore(app);
        try {
            const userDocRef = doc(fs, "users", uid);
            await setDoc(userDocRef, {});
            console.log("new user added to users: ", uid);
        } catch (e) {
            console.error("error adding user to users: ", e);
        }
    }

    async function ensureUserInUsers(uid: string) {
        const fs = getFirestore(app);
        try {
            const userDocRef = doc(fs, "users", uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (!userDocSnapshot.exists()) {
                console.log("user wasn't in users, adding now...");
                addDocToUsers(uid);
            }
        } catch (e) {
            console.error("error ensuring user in users: ", e);
        }
    }

    useEffect(() => {
        const authInstance = getAuth(app);
        setAuth(authInstance);
        const unsubscribe = onAuthStateChanged(authInstance, user => {
            if (user) {
                console.debug("signed in");
                ensureUserInUsers(user.uid);
                setUser(user);
            } else {
                console.debug("not signed in");
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [app]);
	
	return (
		<>
			<Router>
				<Navbar app={app} auth={auth} user={user} />
                <div className="main">
                    <Routes>
                        <Route path='/' element={<Home app={app} />} />
                        <Route path='/set/:set_id' element={<Set app={app}/>} />
                        <Route path='/mysets' element={<MySets app={app} user={user} />} />
                        <Route path='/seteditor' element={<SetEditor app={app} user={user} />} />
                        <Route path='/seteditor/:set_id' element={<SetEditor app={app} user={user} />} />
                        <Route path="/learn/:set_id" element={<Learn app={app} />} />
                    </Routes>
                </div>
				<Footer app={app} user={user} />
			</Router>
		</>
	);
}

export default App;