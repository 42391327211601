import '../css/CardTemplate.css';
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent, useState } from 'react';
import { CardTemplateState } from '../CardTemplateState';

interface CardTemplateProps {
    cardTemplateState: CardTemplateState;
    handleTermChange: (term: string) => void;
    handleDefinitionChange: (definition: string) => void;
    deleteCardTemplateState: (index: number) => void;
}

const CardTemplate: FunctionComponent<CardTemplateProps> = ({ cardTemplateState, handleTermChange, handleDefinitionChange, deleteCardTemplateState }) => {
    const { index, term, definition } = cardTemplateState;
    return (
        <div className="card-container">
            <div className="card-index">
                {cardTemplateState.index + 1}
            </div>
            <div className="textarea-container">
                <textarea
                    className="term-textarea custom-textarea"
                    name="term"
                    placeholder="term"
                    value={term}
                    onChange={(e) => handleTermChange(e.target.value)}>
                </textarea>
                <textarea
                    className="custom-textarea"
                    name="definition"
                    placeholder="definition"
                    value={definition}
                    onChange={(e) => handleDefinitionChange(e.target.value)}>
                </textarea>
            </div>
            <div className="delete-card-btn" onClick={() => deleteCardTemplateState(cardTemplateState.index)}>
                <i className="bi bi-x-lg big-x"></i>
            </div>  
        </div>
    );
};

export default CardTemplate;