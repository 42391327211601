import { FirebaseApp } from "firebase/app";
import { addDoc, collection, deleteDoc, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { SetData } from "./SetData";

export async function addSet(app: FirebaseApp, setData: SetData): Promise<string | null> {
    const fb = getFirestore(app);
    const setsCollection = collection(fb, 'sets');
    try {
        const setDocRef = await addDoc(setsCollection, setData);
        console.log('set saved with id: ', setDocRef.id);
        return setDocRef.id;
    } catch (error) {
        console.error('error saving set: ', error);
        return null;
    }
}

export async function getSet(app: FirebaseApp, id: string): Promise<SetData | null> {
    const fb = getFirestore(app);
    const setRef = doc(fb, "sets", id);
    try {
        const docSnapshot = await getDoc(setRef);
        if (docSnapshot.exists()) {
            return docSnapshot.data() as SetData;
        } else {
            console.log('set not found');
            return null;
        }
    } catch (error) {
        console.error('error getting set:', error);
        return null;
    }
}

export async function updateSet(app: FirebaseApp, id: string, setData: SetData): Promise<boolean> {
    const fb = getFirestore(app);
    const setRef = doc(fb, 'sets', id);
    try {
        const existingSetData = await getSet(app, id);
        if (!existingSetData) {
            console.error('set not found');
            return false;
        }
        setData.createdAt = existingSetData.createdAt;
        if (existingSetData.hash === setData.hash) {
            setData.modifiedAt = existingSetData.modifiedAt;
        }
        await setDoc(setRef, setData);
        console.log('set updated successfully');
        return true;
    } catch (error) {
        console.error('error updating set:', error);
        return false;
    }
}

export async function deleteSet(app: FirebaseApp, id: string): Promise<boolean> {
    const fb = getFirestore(app);
    const setRef = doc(fb, 'sets', id);
    try {
        await deleteDoc(setRef);
        console.log('set deleted successfully');
        return true;
    } catch (error) {
        console.error('error deleting set:', error);
        return false;
    }
}