import '../css/Navbar.css';
import { Auth, GoogleAuthProvider, User, signInWithPopup } from '@firebase/auth';
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';

interface NavbarProps {
    app: FirebaseApp;
    auth: Auth | null;
    user: User | null;
}

const Navbar: FunctionComponent<NavbarProps> = ({ app, auth, user }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const handleGoogleSignIn = () => {
        if (auth) {
            signInWithPopup(auth, new GoogleAuthProvider())
                .then((result) => {
                }).catch((e) => {
                    console.error("error signing in with Google:", e);
                });
        }
    };

    const handleSignOut = () => {
        if (auth) {
            auth.signOut();
        }
    };

    function toggleHamburger() {
        const hamburgerContainer = document.getElementById('hamburger-container');
        if (!hamburgerContainer) { return; }
        hamburgerContainer.classList.toggle("show-hamburger-container");
    }
    
    return (
        <nav className="navbar">
            <i className="bi bi-list hamburger-btn" onClick={toggleHamburger} />
            <div id="hamburger-container" className="hamburger-container">
                <NavbarLinks handleSignIn={handleGoogleSignIn} handleSignOut={handleSignOut} user={user}/>
            </div>
            <div className="navbar-container">
                <NavbarLinks handleSignIn={handleGoogleSignIn} handleSignOut={handleSignOut} user={user}/>
            </div>
            {user && 
                <>
                    <div className="user-container">
                        {/* <i className="bi bi-person-fill person"></i> */}
                        {user.email}
                    </div>
                </>
            }
        </nav>
    );
};

interface NavbarLinksProps {
    user: User | null;
    handleSignIn: () => void;
    handleSignOut: () => void;
}

const NavbarLinks: FunctionComponent<NavbarLinksProps> = ({ handleSignIn, handleSignOut, user }) => {
    return (
        <>
            <Link className="navbar-link backpack" to="/"><i className="bi bi-backpack2-fill"></i></Link>
            {user && <Link className="navbar-link" to="/mysets">My Sets</Link>}
            {user && <Link className="navbar-link" to="/seteditor/">Set Editor</Link>}
            {!user && <button className="navbar-btn" onClick={handleSignIn}>Sign In</button>}
            {user && <button className="navbar-btn" onClick={handleSignOut}>Sign Out</button>}
        </>
    );
};

export default Navbar;