import '../css/Learn.css';
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SetData } from '../SetData';
import { getSet } from '../setService';

interface LearnProps {
    app: FirebaseApp;
}

interface answerOption {
    correct: boolean;
    text: string;
    border: string;
}

const Learn: FunctionComponent<LearnProps> = ({ app }) => {
    const { set_id } = useParams<{ set_id: string }>();

    const [cardset, setCardset] = useState<SetData | null>(null);
    const [proompt, setProompt] = useState("");
    const [options, setOptions] = useState<answerOption[]>([]);
    const [answerable, setAnswerable] = useState(true);
    const [questionCount, setQuestionCount] = useState(10 - 1);

    const navigate = useNavigate();

    const optionsCount = 4;

    function addOption(newOption: answerOption): void {
        setOptions(prevOptions => [...prevOptions, newOption]);
    };

    function randomOrder(n: number): number[] {
        const list: number[] = [];
        for (let i = 0; i < n; i++) {
            list.push(i);
        }
        for (let i = list.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [list[i], list[j]] = [list[j], list[i]];
        }
        return list;
    }

    function genQuestion(fetchedCards: SetData | null) {
        if (!fetchedCards) { return; }
        setOptions([]);
        const cardCount = fetchedCards.cards.length;
        const cardOrder = randomOrder(cardCount);
        setProompt(fetchedCards.cards[cardOrder[0]].term);
        const maxCount = Math.min(optionsCount, cardCount)
        const location = Math.floor(Math.random() * maxCount);
        let cursor = 1;
        for (let i = 0; i < maxCount; ++i) {
            if (i === location) {
                addOption({
                    correct: true,
                    text: fetchedCards.cards[cardOrder[0]].definition,
                    border: ""
                });
            } else {
                addOption({
                    correct: false,
                    text: fetchedCards.cards[cardOrder[cursor++]].definition,
                    border: ""
                });
            }
        }
    }

    function learn(fetchedCards: SetData) {
        setAnswerable(true);
        genQuestion(fetchedCards);
    }

    useEffect(() => {
        async function asyncSetSet() {
            if (!set_id) {
                console.debug("no set id");
                navigate("/mysets");
                return;
            }
            const fetchedData: SetData | null = await getSet(app, set_id);
            if (!fetchedData) {
                console.debug("no set under this id");
                navigate("/mysets");
                return;
            }
            if (fetchedData && fetchedData.cards.length === 0) {
                console.debug("empty set");
                navigate(`/seteditor/${set_id}`);
                return;
            }
            setCardset(fetchedData);
            learn(fetchedData);
        }
        asyncSetSet();
    }, [app, set_id]);

    function submitAnswer(option: answerOption) {
        if (!answerable) return;
        setAnswerable(false);
        const correctOption = options.find(opt => opt.correct);
        const isCorrect = option.correct;

        const newOptions = options.map(opt => ({
            ...opt,
            border: opt === option ? (isCorrect ? '4px solid green' : '4px solid red') : ''
        }));

        setOptions(newOptions);
    }

    // 9 8 7 6 5 4 3 2 1 0
    function proceed() {
        setQuestionCount(prevQuestionCount => prevQuestionCount - 1);
        if (questionCount === 0) {
            navigate(`/set/${set_id}`);
        }
        genQuestion(cardset);
        setAnswerable(true);
    }

    return (
        <div className="learn-container">
            {cardset &&
                <>
                    <Link to={`/set/${set_id}`} className="exit-link">
                        <i className="bi bi-arrow-left exit-arrow"></i>
                    </Link>

                    <div className="game-container">

                        <div className="proompt-container scaling-font">
                            {proompt}
                        </div>

                        <div className="choice-container">
                            {options.map((option, idx) => (
                                <button
                                    key={idx}
                                    className="choice btn scaling-font"
                                    onClick={(e) => submitAnswer(option)}
                                    style={{ border: option.border }}
                                >
                                    {option.text}
                                </button>
                            ))}
                        </div>

                        <button className="btn continue-btn scaling-font" onClick={proceed}>
                            continue
                        </button>
                    </div>
                </>
            }
        </div>
    );
};

export default Learn;