import "../css/CardCarousel.css";
import { FirebaseApp } from "firebase/app";
import { SetData } from "../SetData";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface CardCarouselProps {
    app: FirebaseApp;
    cardset: SetData;
}

export const CardCarousel: FunctionComponent<CardCarouselProps> = ({ app, cardset }) => {
    const [cardidx, setCardidx] = useState(0);
    const [flipped, setFlipped] = useState(false);

    function flipCard() {
        setFlipped(prevFlipped => !prevFlipped);
    };

    function nextCard(direction: boolean) {
        if (cardset.cards.length === 0) { return; }
        setFlipped(false);
        if (direction) {
            setCardidx(prevCardidx => (prevCardidx + 1) % cardset.cards.length);
        } else {
            setCardidx(prevCardidx => (prevCardidx - 1 + cardset.cards.length) % cardset.cards.length);
        }
    }

    return (
        <div className="card-carousel">
            <button className="btn arrow-btn" onClick={() => nextCard(false)}>
                <i className="bi bi-arrow-left-short arrow"></i>
            </button>

            <div className={`card-outer ${flipped ? 'flipped' : ''}`} onClick={flipCard}>
                <div className="card-inner">
                    <div className="card-front">
                        <h2>{cardset.cards[cardidx].term}</h2>
                    </div>
                    <div className="card-back">
                        <p>{cardset.cards[cardidx].definition}</p>
                    </div>
                </div>
            </div>

            <button className="btn arrow-btn" onClick={() => nextCard(true)}>
                <i className="bi bi-arrow-right-short arrow"></i>
            </button>
        </div>
    );
}
