import '../css/Home.css';
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface HomeProps {
    app: FirebaseApp;
}

const Home: FunctionComponent<HomeProps> = ({ app }) => {
    return (
        <div className="home-container">
            <h1>Hi! Welcome to quiz-co!</h1>
            <p>
                Here, you can make flashcards to study vocab, 
                history, foreign languages, and other subjects! 
                Feel free to sign in with Google (the button is up 
                on the navbar) and get cracking.
            </p>
        </div>
    );
};

export default Home;