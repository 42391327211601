import '../css/SetEditor.css';
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent, useEffect, useState } from 'react';
import CardTemplate from './CardTemplate';
import { CardTemplateState } from '../CardTemplateState';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addSet, deleteSet, getSet, updateSet } from '../setService';
import { SetData } from '../SetData';
import { User } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';

function stringifyCardTemplateStates(cards: CardTemplateState[]): string {
    const formattedStrings = cards.map(card =>
        `Index: ${card.index}, Term: ${card.term}, Definition: ${card.definition}`
    );
    return formattedStrings.join('\n');
}

async function calculateSHA256(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
}

interface SetEditorProps {
    app: FirebaseApp;
    user: User | null;
}

const SetEditor: FunctionComponent<SetEditorProps> = ({ app, user }) => {
    const navigate = useNavigate();
    const { set_id } = useParams<{ set_id: string }>();

    const [setName, setSetName] = useState("");
    const [cardTemplateStates, setCardTemplateStates] = useState<CardTemplateState[]>([]);

    const [isCoverVisible, setIsCoverVisible] = useState(false);

    useEffect(() => {
        async function asyncSetCardTemplatesStates() {
            if (!set_id) { return; }
            const setData: SetData | null = await getSet(app, set_id);
            if (setData) {
                setCardTemplateStates(setData.cards);
                setSetName(setData.setName);
            } else {
                console.debug("no set under this id");
                navigate("/mysets");
            }
        }
        if (set_id == null) {
        } else {
            asyncSetCardTemplatesStates();
        }
    }, [app, set_id]);

    function addCardTemplateState() {
        const newCardTemplateState: CardTemplateState = {
            index: cardTemplateStates.length,
            term: "",
            definition: ""
        }
        setCardTemplateStates(prevCardTemplateStates => [...prevCardTemplateStates, newCardTemplateState]);
    }

    function deleteCardTemplateState(index: number) {
        setCardTemplateStates(prevCardTemplateStates => {
            const updatedCardTemplateStates = prevCardTemplateStates.filter(cardTemplateState => cardTemplateState.index !== index);
            const updatedStates = updatedCardTemplateStates.map((cardTemplateState, idx) => ({ ...cardTemplateState, index: idx }));
            return updatedStates;
        });
    };

    function handleCardTemplateStateChange(index: number, term: string, definition: string) {
        if (term.length > 1000 || definition.length > 1000) {
            alert('Character limit exceeded (1000 characters)');
            return;
        }
        
        setCardTemplateStates(prevCardTemplateStates =>
            prevCardTemplateStates.map(cardTemplateState => {
                if (cardTemplateState.index === index) {
                    return {
                        index: cardTemplateState.index,
                        term: term,
                        definition: definition
                    };
                } else {
                    return cardTemplateState;
                }
            })
        );
    }

    async function handleSaveSet(): Promise<string | null> {
        if (!user) {
            console.debug("no user");
            return null;
        }
        const now = Timestamp.now();
        const freshSet: SetData = {
            setName: setName,
            author: user.uid,
            cards: cardTemplateStates,
            createdAt: now,
            modifiedAt: now,
            hash: await calculateSHA256(stringifyCardTemplateStates(cardTemplateStates))
        }
        if (set_id) {
            if (!await updateSet(app, set_id, freshSet)) {
                alert("couldn't update");
                return null;
            }
            return set_id;
        } else {
            const setId: string | null = await addSet(app, freshSet);
            if (setId) {
                navigate(`/seteditor/${setId}`);
                return setId;
            } else {
                alert("couldn't save set");
                return null;
            }
        }
    }

    async function handleNavigateHome() {
        const setId = await handleSaveSet();
        if (setId) {
            navigate(`/set/${setId}`);
        } else {
            alert("failed to save. You can only edit the sets you authored.");
            console.error("failed to save");
        }
    }

    async function handleDeleteSet() {
        if (!set_id) {
            console.debug("no set id");
            return;
        }
        const confirmed = window.confirm("Are you sure you want to delete this set?");
        if (confirmed) {
            if (await deleteSet(app, set_id)) {
                navigate("/mysets");
            }
        }
    }

    const handleCoverClick = (event: any) => {
        event.stopPropagation();
        toggleCover();
    };

    const toggleCover = () => {
        setIsCoverVisible(prevState => !prevState);
    };

    return (
        <div className="set-container">
            <input
                id="setname"
                className="set-name-input"
                type="text"
                placeholder="Set Name"
                value={setName}
                onChange={(e) => setSetName(e.target.value)}>
            </input>
            <button className="new-card-btn" onClick={addCardTemplateState}>
                <i className="bi bi-plus-square"></i>
            </button>
            <div className="cards-container">
                {cardTemplateStates.slice().reverse().map((cardTemplateState) => (
                    <CardTemplate
                        key={cardTemplateState.index}
                        cardTemplateState={cardTemplateState}
                        handleTermChange={(newTerm: string) => handleCardTemplateStateChange(cardTemplateState.index, newTerm, cardTemplateState.definition)}
                        handleDefinitionChange={(newDefinition: string) => handleCardTemplateStateChange(cardTemplateState.index, cardTemplateState.term, newDefinition)}
                        deleteCardTemplateState={(index: number) => deleteCardTemplateState(index)}
                    />
                ))}
            </div>
            <div className="btn-container">
                <button className="btn save-btn" onClick={handleSaveSet}>
                    <i className="bi bi-floppy floppy"></i>
                    <div className="save-btn-label">Save set</div>
                </button>
                {cardTemplateStates.length > 0 ?
                    <>
                        <button className="btn set-home-btn" onClick={handleNavigateHome}>
                            <i className="bi bi-house-door house"></i>
                            Go to set home
                        </button>
                    </>
                    :
                    <></>
                }
                <div className="delete-btn-container">
                    <button className="btn delete-btn" onClick={handleDeleteSet}>
                        <i className="bi bi-trash3 trash"></i>
                        <div className="delete-btn-label">Delete set</div>
                    </button>
                    <div className={`delete-btn-cover ${isCoverVisible ? 'cover-flipped' : ''}`} onClick={handleCoverClick}></div>
                </div>
            </div>
        </div>
    );
};

export default SetEditor;