import "../css/MySets.css";
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent, useEffect, useState } from 'react';
import { collection, query, where, getDocs, Firestore, getFirestore } from 'firebase/firestore';
import { User } from '@firebase/auth';
import { Link } from 'react-router-dom';
import { SetData } from '../SetData';

interface MySetsProps {
    app: FirebaseApp;
    user: User | null;
}

interface SetDataWithId extends SetData{
    id: string;
}

const MySets: FunctionComponent<MySetsProps> = ({ app, user }) => {

    const [sets, setSets] = useState<SetDataWithId[]>([])

    useEffect(() => {
        async function getUserSets() {
            if (user) {
                const userSets = await getSetsByAuthor(getFirestore(app), user.uid);
                const sortedSets = userSets.sort((a, b) => b.modifiedAt.seconds - a.modifiedAt.seconds);
                setSets(sortedSets);
            } else {
                console.debug("no user");
                setSets([]);
            }
        }
        getUserSets();
    }, [user]);

    async function getSetsByAuthor(db: Firestore, userId: string): Promise<SetDataWithId[]> {
        const setsRef = collection(db, 'sets');
        const q = query(setsRef, where('author', '==', userId));
        try {
            const querySnapshot = await getDocs(q);
            const sets: SetDataWithId[] = [];
            querySnapshot.forEach((doc) => {
                sets.push({ 
                    id: doc.id,
                    ...doc.data() 
                } as SetDataWithId);
            });
            return sets;
        } catch (error) {
            console.error('error getting sets by author: ', error);
            return [];
        }
    }

    return (
        <div className="sets-container">
            <div className="new-set-btn-container">
                <Link className="new-set-btn" to="/seteditor">
                    <i className="bi bi-plus-square"></i>
                </Link>
            </div>
            {sets.map((set, index) => (
                <Link className="set-item" key={index} to={`/set/${set.id}`}>
                    <i className="bi bi-stack"></i> {set.setName}
                </Link>
            ))}
        </div>
    );
};

export default MySets;