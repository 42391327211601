import '../css/Footer.css';
import { User } from '@firebase/auth';
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface FooterProps {
    app: FirebaseApp;
    user: User | null;
}

const Footer: FunctionComponent<FooterProps> = ({ app, user }) => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <a className="footer-link" href="mailto:qiuzco@gmail.com">contact</a>
                    {/* <a className="footer-link" href="#">footerlink</a>
                    <a className="footer-link" href="#">footerlink</a> */}
                </div>
                <div className="footer-column">
                    <a className="footer-link" href="#">v 0.1.0</a>
                    {/* <a className="footer-link" href="#">footerlink</a>
                    <a className="footer-link" href="#">footerlink</a> */}
                </div>
                <div className="footer-column">
                    <a className="footer-link" href="mailto:qiuzco@gmail.com">report error</a>
                    {/* <a className="footer-link" href="#">footerlink</a>
                    <a className="footer-link" href="#">footerlink</a> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;