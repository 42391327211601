import "../css/Set.css";
import { FirebaseApp } from 'firebase/app';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SetData } from '../SetData';
import { getSet } from '../setService';
import { CardCarousel } from "./CardCarousel";

interface SetProps {
    app: FirebaseApp;
}


const Set: FunctionComponent<SetProps> = ({ app }) => {
    const { set_id } = useParams<{ set_id: string }>();

    const [cardset, setCardset] = useState<SetData | null>(null);

    const navigate = useNavigate();
    
    useEffect(() => {
        async function asyncSetSet() {
            if (!set_id) {
                console.debug("no set id");
                navigate("/mysets");
                return;
            }
            const fetchedData: SetData | null = await getSet(app, set_id);
            if (!fetchedData) {
                console.debug("no set under this id");
                navigate("/mysets");
                return;
            }
            if (fetchedData && fetchedData.cards.length === 0) {
                console.debug("empty set");
                navigate(`/seteditor/${set_id}`);
                return;
            }
            setCardset(fetchedData);
        }
        asyncSetSet();
    }, [app, set_id]);

    return (
        <div className="set-dashboard">
            {cardset && cardset.cards.length > 0 ? 
                <>
                    <CardCarousel app={app} cardset={cardset}/>
                    <Link className="gear-link" to={`/seteditor/${set_id}`}>
                        <i className="bi bi-gear gear"></i>
                    </Link>
                    <div className="set-games">
                        <Link to={`/learn/${set_id}`} className="game-link">
                            Learn
                        </Link>
                        <Link to={`#`} className="game-link disabled">
                            Match
                        </Link>
                        <Link to={`#`} className="game-link disabled">
                            Quiz
                        </Link>
                        <Link to={`#`} className="game-link disabled">
                            Review
                        </Link>
                    </div>
                </>
                :
                <>
                    no cardset with this id! <Link to="/mysets">click to go to My Sets</Link>
                </>
            }
        </div>
    );
};

export default Set;